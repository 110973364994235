
.verificationRoot{
    text-align: center !important;
    color: gray;
    margin-top: 60px;
}

.lockImg {
    padding: 0px 185px;
}

.verifyInstruction {
    text-align: center;
    font-size: large;
}
  
.verifyMsg {
    padding: 8px !important;
}

.loginOtpErrorMsg {
    color: #CF3020;
    text-align: left;
    margin: -22px 0px -10px 0px !important;
}

.loginCancel {
    margin-top: 15px !important;
}
  
.timerSection {
    display: flex;
    justify-content: space-between;
}