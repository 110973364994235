
.totpRoot{
    padding: 0px 10px 0px 30px !important;
    margin-top: -25px !important;
    // margin-bottom: 55px !important;
    // overflow: scroll !important;
}

.totp {
    display: flex;
    border: 1px solid #D3D3D3;
    height: 455px;
    width: 1000px;
    border-radius: 10px;
}

.codeSection {
    text-align: center;
    text-align: center;
    width: 500px;
    padding: 36px;
}

.activationCode {
    background: #F0F0F0;
    margin: 10px 50px;
    height: 30px;
    align-content: center;
}

.otpVerifySection {
    text-align: center;
    text-align: center;
    width: 600px;
    padding: 40px;
    border-left: 1px solid #D3D3D3;
}

.navigateMethod {
    margin-top: 15px !important;
    width: -webkit-fill-available !important;
    width: -moz-available !important;
}

.navigateParent {
    margin-top: 15px;
    justify-content: space-between;
    display: flex;
}

.navigates {
    width: 150px;
}

.emailSection {
    text-align: center;
    text-align: center;
    width: 500px;
    padding: 100px 50px;
    border-left: 1px solid #D3D3D3;
}

.userLogo {
    margin: 30px 0px 10px 0px;
}

.emailUserLogo {
    height: 45px !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.otpField {
    margin: 20px 0px !important;
}

.otpErrorMsg {
    color: #CF3020;
    text-align: left;
    margin: -10px 0px 10px 0px !important;
}

.activateBtn {
    width: -webkit-fill-available !important;
    width: -moz-available !important;
}

.successNotification {
    margin: -20px 0px 10px 0px !important;
}

.MuiAlert-message-210 {
    font-weight: 700 !important;
}

.emailVerifyBtn {
    margin-top: 10px !important;
    color: white !important;
}

.dialogHeader {
    height: 40px;
    display: flex;
    justify-content: space-between;
}

.dialogTitle {
    padding: 10px;
    margin-left: 155px !important;
}

.successMsg {
    margin: 15px;
    text-align: center;
    font-size: medium;
}

.actionBtn {
    margin: 0 10px !important;
    width: -webkit-fill-available !important;
    width: -moz-available !important;
}

.phoneNumbField {
    margin: 20px 0px !important;
    width: -webkit-fill-available !important;
    width: -moz-available !important;
    height: 40px;
    display: inline-flex;
    justify-content: space-between;
}

.PhoneInputCountryIconImg {
    height: 25px;
    margin: 8px;
    border: none;
}

.PhoneInputInput {
    width:350px
}

.PhoneInputCountry {
    display: inline-flex;
}