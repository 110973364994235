.myAccountRoot{
    padding: 0px 10px 75px 30px !important;
    margin-top: -37px !important;
    // margin-bottom: 55px !important;
    // height: 100vh !important;
    // overflow: scroll !important;
}

.mfaHeader {
    border: 1px solid #D3D3D3;
    width: 1000px;
    border-radius: 4px;
}

.mfaTitle {
    background: #F0F0F0;
    height: 30px !important;
    padding: 3px 3px 3px 15px;
}

.mfaContent {
    display: flex;
    padding: 15px;
    justify-content: space-between;
}

.mfaEnableBtns {
    display: inline-flex;
}

.mfaEnabled {
    color: white !important;
    background: #CF3020;
    border-radius: 10px;
    border: none;
}

.mfaDisabled {
    color: gray;
    background: #F0F0F0;
    border-radius: 10px;
    border: none;
}

.mfaTypes {
    margin-top: 20px;
}

.mfaType {
    display: flex;
}

.types {
    display: grid;
}

.mfaMethod {
  display: inline-flex;
  flex-direction: column;
  border: 1px solid #D3D3D3;
  padding: 10px;
  width: 650px;
  border-radius: 4px;
  margin: 2px 0px 6px 10px;
}

.cancelBtn{
    margin-left: 10px !important;
}

#mfaEnableBtn:focus {
    border: none !important;
}

#mfaDisableBtn:focus {
    border: none !important;
}

.proceedBtn {
    color: white !important;
}

.mfaActionBtns {
    input[type="checkbox"].toggle {
        opacity: 0;
        position: absolute;
        left: -99999px;
    }
    input[type="checkbox"].toggle + label {
        height: 40px;
        line-height: 40px;
        background-color: #ccc;
        padding: 0px 16px;
        border-radius: 16px;
        display: inline-block;
        position: relative;
        cursor: pointer;
        -moz-transition: all 0.25s ease-in;
        -o-transition: all 0.25s ease-in;
        -webkit-transition: all 0.25s ease-in;
        transition: all 0.25s ease-in;
        -moz-box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
        box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
    }
    input[type="checkbox"].toggle + label:before, input[type="checkbox"].toggle + label:hover:before {
        content: ' ';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 46px;
        height: 36px;
        background: #fff;
        z-index: 2;
        -moz-transition: all 0.25s ease-in;
        -o-transition: all 0.25s ease-in;
        -webkit-transition: all 0.25s ease-in;
        transition: all 0.25s ease-in;
        -moz-border-radius: 14px;
        -webkit-border-radius: 14px;
        border-radius: 14px;
    }
    input[type="checkbox"].toggle + label .off, input[type="checkbox"].toggle + label .on {
        color: #fff;
    }
    input[type="checkbox"].toggle + label .off {
        margin-left: 46px;
        display: inline-block;
    }
    input[type="checkbox"].toggle + label .on {
        display: none;
    }
    input[type="checkbox"].toggle:checked + label .off {
        display: none;
    }
    input[type="checkbox"].toggle:checked + label .on {
        margin-right: 46px;
        display: inline-block;
    }
    input[type="checkbox"].toggle:checked + label, input[type="checkbox"].toggle:focus:checked + label {
        background-color: #32ca32;
    }
    input[type="checkbox"].toggle:checked + label:before, input[type="checkbox"].toggle:checked + label:hover:before, input[type="checkbox"].toggle:focus:checked + label:before, input[type="checkbox"].toggle:focus:checked + label:hover:before {
        background-position: 0 0;
        top: 2px;
        left: 100%;
        margin-left: -48px;
    }
}

.actionBtns {
    margin: 10px;
}

.defaultSection {
    display: flex;
    border-top: 1px solid #D3D3D3;
    border-bottom: 1px solid #D3D3D3;
    padding: 6px;
    // border-radius: 4px;
    margin-bottom: 12px;
    width: 1000px;
}

.defaultDropDown {
    margin-left: 50px;
    height: 34px;
    width: 200px;
}

.defaultText {
    padding-top: 6px;
    font-weight: 600;
    font-size: medium;
}

.addIcon {
    color: #CF3020 !important;
}

.deleteIcon {
    color: #CF3020 !important;
}

.warningTitle {
    padding: 10px;
    margin-left: 200px !important;
}

.tickIcon {
    color: #32ca32 !important;
    margin-top: -10px !important;
}

.mfaWrapper {
    display: flex;
    justify-content: space-between;
}

.DisableActions {
    display: flex;
    justify-content: center;
}

.disableActionBtns {
    margin: 0 10px !important;
    width: -webkit-fill-available !important;
    width: -moz-available !important;
    width: 150px !important;
}

.disableDialogTitle {
    padding: 10px;
    margin-left: 204px !important;
}